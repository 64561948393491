import React, { useMemo, useRef } from 'react'
import { SECTION_MARGIN } from '../../../utils/constants'
// Types
import { SectionTestimonialSlideshow } from '../../../types/sections'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Flex, Box, Heading, Text, Image, NavButton, Icons, Button } from '../../../atoms'
import { StaticImage } from 'gatsby-plugin-image'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import { GroupedTestimonials } from './TestimonialSlideshow.styled'
import { useLocale } from '../../../hooks/useLocale'

const TestimonialSlideshow: React.FC<SectionTestimonialSlideshow> = (props) => {
  const { id, sectionId, headline, variant, testimonials } = props
  const { locale } = useLocale()

  const carousel = useRef<AliceCarousel>(null)

  const items = useMemo(() => {
    if (variant === 'single') {
      return testimonials.map((testimonial) => (
        <Box key={testimonial.id} position={'relative'} p={[6, 10, 80]} zIndex={2}>
          <Box mb={6}>
            <StaticImage
              formats={['auto']}
              draggable={false}
              src={'../../../images/quote_symbol.png'}
              alt={''}
              placeholder={'none'}
              width={50}
            />
          </Box>
          <blockquote>
            <Heading as={'h4'} type={'heading2'}>
              {testimonial.quote}
            </Heading>
          </blockquote>

          {testimonial.customerStory && (
            <Box mt={3}>
              <Button
                buttonType={'text'}
                link={testimonial.customerStory.pageInfo}
                label={locale === 'fr' ? 'Lire le cas client' : 'Read customer story'}
              />
            </Box>
          )}

          <Flex mt={8} alignItems={'center'}>
            {testimonial.clientLogo && (
              <Box flex={'0 0 auto'} mr={6}>
                <Image
                  draggable={false}
                  alt={testimonial.clientLogo.alt || testimonial.clientName || ''}
                  image={testimonial.clientLogo.gatsbyImageData}
                />
              </Box>
            )}

            <Box flex={'1'}>
              {!!testimonial.clientName && (
                <Text type={'large'} fontWeight={'bold'}>
                  {testimonial.clientName}
                </Text>
              )}
              {!!testimonial.clientRole && (
                <Box mt={1}>
                  <Text type={'small'}>{testimonial.clientRole}</Text>
                </Box>
              )}
            </Box>
          </Flex>
        </Box>
      ))
    }
    if (variant === 'grouped') {
      return testimonials.map((testimonial) => (
        <Box px={'15px'} height={'100%'}>
          <Flex
            key={testimonial.id}
            flexDirection={'column'}
            alignItems={'stretch'}
            justifyContent={'space-between'}
            position={'relative'}
            p={[4, 4, 5]}
            boxShadow={'card'}
            borderColor={'dark'}
            borderStyle={'solid'}
            borderWidth={'1px'}
            borderRadius={8}
            height={'100%'}
          >
            <Box flex={'1'}>
              <Box mb={6}>
                <StaticImage
                  formats={['auto']}
                  draggable={false}
                  src={'../../../images/quote_symbol.png'}
                  alt={''}
                  placeholder={'none'}
                  width={50}
                />
              </Box>

              <Text type={'large'}>{testimonial.quote}</Text>

              {testimonial.customerStory && (
                <Box mt={3}>
                  <Button
                    buttonType={'text'}
                    link={testimonial.customerStory.pageInfo}
                    label={locale === 'fr' ? 'Lire le cas client' : 'Read customer story'}
                  />
                </Box>
              )}
            </Box>

            <Flex flex={'0 0 auto'} mt={6} alignItems={'center'}>
              {testimonial.clientLogo && (
                <Box flex={'0 0 auto'} mr={4}>
                  <Image
                    draggable={false}
                    alt={testimonial.clientLogo.alt || testimonial.clientName || ''}
                    image={testimonial.clientLogo.gatsbyImageData}
                  />
                </Box>
              )}

              <Box flex={'1'}>
                {!!testimonial.clientName && (
                  <Text type={'regular'} fontWeight={'bold'}>
                    {testimonial.clientName}
                  </Text>
                )}
                {!!testimonial.clientRole && (
                  <Box mt={1}>
                    <Text type={'small'}>{testimonial.clientRole}</Text>
                  </Box>
                )}
              </Box>
            </Flex>
          </Flex>
        </Box>
      ))
    }
  }, [testimonials, variant])

  return (
    <Box as={'section'} id={sectionId || id}>
      <Box my={SECTION_MARGIN}>
        <Grid>
          <Row>
            <Col xs={12}>
              {headline && (
                <Box mb={6}>
                  <Heading as={'h2'} type='heading1'>
                    {headline}
                  </Heading>
                </Box>
              )}

              {variant === 'single' && (
                <Box position={'relative'} backgroundColor={'violetLight'} borderRadius={'small'} overflow={'hidden'}>
                  <Box
                    position={'absolute'}
                    top={0}
                    left={0}
                    width={'100%'}
                    height={'100%'}
                    zIndex={1}
                    overflow={'hidden'}
                  >
                    <Box position={'absolute'} top={-210} left={[-200, -50]}>
                      <StaticImage
                        formats={['auto']}
                        draggable={false}
                        src={'../../../images/ellipse_violet_testimonial_left.png'}
                        alt={''}
                        placeholder={'none'}
                        width={400}
                      />
                    </Box>
                    <Box position={'absolute'} bottom={[-200, -30]} right={[-260, -140]}>
                      <StaticImage
                        formats={['auto']}
                        draggable={false}
                        src={'../../../images/ellipse_violet_testimonial_right.png'}
                        alt={''}
                        placeholder={'none'}
                        width={465}
                      />
                    </Box>
                  </Box>

                  <Box position={'relative'} zIndex={2}>
                    <AliceCarousel ref={carousel} items={items} infinite disableButtonsControls disableDotsControls />

                    <Flex alignItems={'center'} position={'absolute'} bottom={[6, 10, 80]} right={[6, 10, 80]} gap={3}>
                      <NavButton onClick={(e) => carousel?.current?.slidePrev(e)}>
                        <Icons.ArrowLeft size={20} />
                      </NavButton>
                      <NavButton onClick={(e) => carousel?.current?.slideNext(e)}>
                        <Icons.ArrowRight size={20} />
                      </NavButton>
                    </Flex>
                  </Box>
                </Box>
              )}

              {variant === 'grouped' && (
                <>
                  <GroupedTestimonials>
                    <AliceCarousel
                      ref={carousel}
                      items={items}
                      responsive={{
                        0: { items: 1 },
                        568: { items: 2 },
                        1024: { items: 3 },
                      }}
                      renderDotsItem={({ isActive }) => {
                        return (
                          <Box backgroundColor={isActive ? 'dark' : 'grey20'} width={8} height={8} borderRadius={8} />
                        )
                      }}
                      renderNextButton={({ isDisabled }) => {
                        return (
                          <NavButton disabled={isDisabled} onClick={(e) => carousel?.current?.slideNext(e)}>
                            <Icons.ArrowRight size={20} />
                          </NavButton>
                        )
                      }}
                      renderPrevButton={({ isDisabled }) => {
                        return (
                          <NavButton disabled={isDisabled} onClick={(e) => carousel?.current?.slidePrev(e)}>
                            <Icons.ArrowLeft size={20} />
                          </NavButton>
                        )
                      }}
                    />
                  </GroupedTestimonials>
                </>
              )}
            </Col>
          </Row>
        </Grid>
      </Box>
    </Box>
  )
}

export default TestimonialSlideshow
